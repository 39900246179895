<template>
  <div class="proposal">
    <vue-headful
      :title="`${metaTitle} | Octoa`"
    />
    <v-add-tax-modal
      :show.sync="isShowTaxModal"
      @close="closeTaxModal"
      @saved="savedTaxModal"
    />

    <invoice-email-slide-in
      v-if="project"
      :project.sync="project"
      :mode.sync="emailEditMode"
      :show.sync="showEmailSlide"
      :email.sync="email"
      @close="hideEmailSlideIn"
      @saved="savedEmailSlideIn"
    />

    <invoice-email-slide-in
      v-if="!project"
      :mode.sync="emailEditMode"
      :show.sync="showEmailSlide"
      :email.sync="email"
      :invoice.sync="invoice"
      @close="hideEmailSlideIn"
      @saved="savedEmailSlideIn"
    />

    <modal
      name="paymentModal"
      class="modal overflow-visible"
      width="90%"
      height="280"
      :max-width="600"
      :adaptive="true"
    >
      <div class="flex justify-between">
        <div class="title">
          Add payment
        </div>
        <div
          class="close "
          @click="$modal.hide('paymentModal')"
        />
      </div>

      <div class="flex mt-5 items-center">
        <div class="w-4/12 md:w-32">
          <div class="pr-2">
            Payment date:
          </div>
        </div>
        <div class="w-8/12 md:w-4/12">
          <v-date-picker
              class="datepicker"
              v-model="addPayment.date"
              name="payment_date"
              :popover="{ visibility: 'click' }"
              :input-props="{
                placeholder: 'Date of payment',
              }"
              :masks="{L: 'WWW, MMM D, YYYY'}"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  type="text"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
        </div>
      </div>

      <div class="flex flex-wrap mt-3 items-center">
        <div class="w-4/12 md:w-32">
          <div class="pr-2">
            Amount ({{ currency.sign }})
          </div>
        </div>
        <div class="w-8/12 md:w-4/12">
          <vue-numeric
            v-model="addPayment.amount"
            :value="remainingDueTotal"
            :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
            :precision="2"
            :currency="currency.sign"
            :minus="false"
            :decimal-separator="currency.decimal_separator"
            :thousand-separator="currency.thousand_separator"
            :min="0"
            :max="remainingDueTotal"
          />
        </div>
        <div class="w-full md:w-auto mt-5 md:mt-0 md:ml-4">
          Remaining amount:
          <vue-numeric
            :value="remainingDueTotal"
            :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
            :precision="2"
            :currency="currency.sign"
            read-only
            :minus="false"
            :decimal-separator="currency.decimal_separator"
            :thousand-separator="currency.thousand_separator"
            :min="0"
          />
        </div>
      </div>

      <div class="flex justify-center md:justify-end flex-wrap mt-8 items-center">
        <div class="w-1/2 text-center md:text-right md:w-3/12">
          <v-button
            class="green-btn"
            @click="recordPayment"
          >
            Add payment
          </v-button>
        </div>
        <div class="w-1/2 text-center md:text-right md:w-1/12 md:ml-5">
          <div
            class="underline text-green cursor-pointer"
            @click="$modal.hide('paymentModal')"
          >
            Cancel
          </div>
        </div>
      </div>
    </modal>

    <v-inner-header
      type="invoice"
      mode="edit"
      :item="invoice"
      :is-saving.sync="isSaving"
      :is-saved.sync="isSaved"
      :user-might-update.sync="userMightUpdate"
      :redirect="$route.params.id ? `/projects/${$route.params.id}` : `/invoices`"
      @addPayment="showAddPayment"
      @click="alertExit"
      @share="copyShareLink"
      @preview="saveAndPreview"
      @deleteItem="deleteItem"
      @markAsSent="markAsSent"
      @markAsUnsent="markAsUnsent"
    />

    <div class="pt-16">
      <div class="flex">
        <div
          v-if="loading"
          class="w-full px-2 text-center"
        >
          <img
            src="@/assets/img/icons/loader.svg"
            width="50"
            class="mt-32 text-center"
          >
        </div>
        <div
          v-else
          class="w-full"
        >
          <invoice-bank-slide-in
            :show.sync="showBankSlide"
            @close="hideBankSlideIn"
          />
          <invoice-contact-slide-in
            v-if="contact"
            :show.sync="showContactSlide"
            :contact.sync="contact"
            @close="hideContactSlideIn"
            @saved="savedContactSlideIn"
          />
          <invoice-select-contact-slide-in
            :show.sync="showSelectContactSlide"
            :contact.sync="contact"
            @close="hideAddContactSlideIn"
            @selectedContact="savedContactSlideIn"
          />
          <div class="bg-grey-black pb-5 px-4">
            <div class="max-w-xl mx-auto text-white pt-6">
              <div class="flex">
                <div class="w-full">
                  <h1 class="text-xl text-white mb-3">
                    <span v-if="!invoiceId">Create an invoice</span> <span v-else>Edit invoice</span>
                  </h1>
                  <!--  <p class="text-grey-darkerlight mb-3">
                    Set tax rate, discount and other invoice options.
                  </p> -->
                  <div
                    v-if="name != '' && name != null"
                    class="input-group"
                  >
                    <input
                      v-model="name"
                      type="text"
                      class="w-full"
                      name="name"
                      placeholder="Name of the invoice"
                    >
                  </div>

                  <div class="my-3">
                    <div
                      class="options-item flex items-center"
                      style="height:42px;"
                    >
                      <div
                        v-if="hasTaxes"
                        class="inline-block"
                      >
                        <v-checkbox
                          id="isTaxEnable"
                          v-model="isTaxEnable"
                          :checked="isTaxEnable"
                          type="dark"
                          label="Apply tax"
                        />
                        <div
                          v-show="isTaxEnable"
                          class="inline-block ml-8"
                        >
                          <v-row class="items-center">
                            <v-col>
                              <div class="select-container">
                                <select v-model="isTaxPriceIncluded">
                                  <option
                                    :value="true"
                                  >
                                    Tax included
                                  </option>
                                  <option
                                    :value="false"
                                  >
                                    Tax excluded
                                  </option>
                                </select>
                              </div>
                              <v-help-tooltip
                                :options="tooltipContent"
                              />
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                      <div
                        v-else
                        class="inline-block"
                      >
                        <a
                          class="cursor-pointer text-green underline"
                          @click="showTaxModal"
                        >
                          Add a tax class
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="proposal-form bg-white shadow max-w-xl">
            <div class="flex bg-white header">
              <div class="w-1/2">
                Status:
                <span
                  v-if="actionDates"
                  class="item-invoice-state capitalize"
                  :class="invoice.state"
                >
                  <span v-if="invoice.state == 'draft'">DRAFT</span>
                  <span v-else>{{ invoice.state }}</span>
                </span>
                <span
                  v-else
                  class="draft item-invoice-state capitalize"
                >
                  UNSENT
                </span>
              </div>
              <div
                class="w-1/2 text-right text-grey-darker"
              >
                <div class="select-container w-1/4">
                  <select v-model="invoice.locale">
                    <option
                      v-for="language in activeLanguages"
                      :key="language.code"
                      :value="language.code"
                    >
                      {{ language.label }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div
              id="step-1"
              class="mt-5"
            >
              <div class="pt-2 pb-8">
                <div class="flex flex-wrap px-8">
                  <div class="w-full md:w-3/5">
                    <div
                      v-if="logo.path"
                      class="logo"
                    >
                      <img
                        :src="logo.path"
                        width="100%"
                        :style="[logo.width > logo.height ? {'max-width' : '278px'} : '', logo.width < logo.height ? { 'width' : 'auto', 'max-height':'250px'} : '', logo.width == logo.height ? { 'width' : '88px', 'height':'88px'} : '' ]"
                      >
                    </div>
                    <div
                      v-else
                      class="flex items-center text-grey-semi-light mb-4 "
                    >
                      <div class="w-2/5 lg:w-4/12">
                        Logo <v-help-tooltip
                          :options="logoTooltipContent"
                        />
                      </div>
                      <div class="w-3/5 lg:w-8/12 ">
                        <div
                          class="border-grey border-dashed border cursor-pointer hover:bg-grey-lighter"
                          style="width:88px; height:88px;"
                          @click="$refs.file.click()"
                        >
                          <img
                            v-show="uploadLoading"
                            src="@/assets/img/icons/loader.svg"
                            width="40"
                            style="transform:translate(-50%,-50%); top:50%; left:50%; "
                          >
                        </div>
                        <input
                          ref="file"
                          type="file"
                          style="display:none;"
                          @change="fileUpload"
                        >
                      </div>
                    </div>
                    <div class="flex flex-wrap items-center ">
                      <div class="w-full md:w-2/5 lg:w-4/12 text-grey-semi-light">
                        {{ $translate('invoiceNumber', invoice.locale) }}
                      </div>
                      <div class="mt-3 md:mt-0 w-full md:w-3/5 lg:w-8/12 ">
                        <div
                          v-if="invoice.state == 'draft'"
                          class="pt-1 mt-px"
                        >
                          <div v-show="isAutoGenerateInvoiceNumber">
                            Generated upon sending <v-help-tooltip
                              :options="invoiceNumberToolTipContent"
                            /> <span class="ml-2 mr-2">or</span> <a
                              class="text-green cursor-pointer underline"
                              @click="switichAutoGenerateInvoiceNumber"
                            >Add manually</a>
                          </div>
                          <div v-show="!isAutoGenerateInvoiceNumber">
                            <input
                              ref="invoiceNumber"
                              v-model="invoiceNumber"
                              class="w-24 ml-2 mr-2 ref-number remove-arrow"
                              type="number"
                              name="invoiceNumber"
                            >
                            <div class="inline-block mt-2 md:mt-0 ">
                              last used: {{ lastInvoiceNumber }}
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <input
                            ref="invoiceNumber"
                            v-model="invoiceNumber"
                            class="w-24 ml-2 mr-2 ref-number remove-arrow"
                            type="number"
                            name="invoiceNumber"
                          >
                          <div class="inline-block mt-2 md:mt-0 ">
                            last used: {{ lastInvoiceNumber }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="my-5 flex flex-wrap items-center text-grey-semi-light">
                      <div class="w-full md:w-2/5 lg:w-4/12">
                        {{ $translate('issued', invoice.locale) }}
                      </div>
                      <div class="mt-3 md:mt-0 w-full md:w-3/5 lg:w-8/12 ">
                        <v-date-picker
                          v-model="issuedAt"
                          class="datepicker max-width"
                          name="issuedAt"
                          :input-props="{
                            readonly: true
                          }"
                          :popover="{ visibility: 'click' }"
                          :masks="{L: 'WWW, MMM D, YYYY'}"
                        >
                          <template v-slot="{ inputValue, inputEvents }">
                            <input
                              type="text"
                              :value="inputValue"
                              v-on="inputEvents"
                            />
                          </template>
                        </v-date-picker>
                      </div>
                    </div>

                    <div class="flex flex-wrap items-center text-grey-semi-light mb-4">
                      <div class="w-full md:w-2/5 lg:w-4/12">
                        {{ $translate('dueDate', invoice.locale) }}
                      </div>
                      <div class="mt-3 md:mt-0 w-full md:w-3/5 lg:w-8/12 ">
                        <v-date-picker
                          v-model="validUntil"
                          class="datepicker max-width"
                          name="validUntil"
                          :input-props="{
                            readonly: true
                          }"
                          :popover="{ visibility: 'click' }"
                          :masks="{L: 'WWW, MMM D, YYYY'}"
                        >
                          <template v-slot="{ inputValue, inputEvents }">
                            <input
                              type="text"
                              :value="inputValue"
                              v-on="inputEvents"
                            />
                          </template>
                        </v-date-picker>
                      </div>
                    </div>
                    <div class="flex flex-wrap items-center text-grey-semi-light mb-4">
                        <div class="w-full md:w-2/5 lg:w-4/12">
                          {{ $translate('subject', invoice.locale) }}
                        </div>
                        <div class="mt-3 md:mt-0 w-full md:w-3/5 lg:w-8/12 ">
                          <input
                            v-model="subject"
                            type="text"
                            name="subject"
                            placeholder="Subject of the invoice"
                          >
                        </div>
                      </div>
                  </div>

                  <div class="w-full md:w-2/5 md:text-right text-sm mt-5 md:mt-0">
                    <div class="flex flex-wrap">
                      <div class="w-1/3 md:w-1/2 md:text-right">
                        <div class="mr-12 text-grey-semi-light leading-loosen">
                          {{ $translate('from', invoice.locale) }}
                        </div>
                      </div>
                      <div class="w-2/3 md:w-1/2 text-left text-grey-darker leading-loosen break-words">
                        <strong class="text-black">{{ user.company.name }}</strong>
                        <pre style="font-family:inherit">{{ user.company.address }}</pre>
                        <pre
                          v-if="user.company.city"
                          style="font-family:inherit"
                        >{{ user.company.city }}<span v-if="user.company.postal_code">,</span> {{ user.company.postal_code }}</pre>
                        {{ user.company.country }}
                      </div>
                    </div>
                    <div
                      v-if="user.companySettings.paymentInfo"
                      class="flex flex-wrap "
                    >
                      <div class="w-1/3 md:w-1/2 md:text-right" />
                      <div class="w-2/3 md:w-1/2 text-left text-grey-darker leading-loosen break-words">
                        <pre>{{ user.companySettings.paymentInfo }}</pre>
                      </div>
                    </div>
                    <div class="flex flex-wrap mt-5">
                      <div class="w-1/3 md:w-1/2 md:text-right">
                        <div class="mr-12 text-grey-semi-light leading-loosen break-words">
                          {{ $translate('to', invoice.locale) }}
                        </div>
                      </div>
                      <div class="w-2/3 md:w-1/2 text-left">
                        <div
                          v-if="!contact && project"
                          class="-mt-2"
                        >
                          <vue-autosuggest
                            :suggestions="suggestions"
                            :input-props="{id:'autosuggest__input',
                                           initialValue: contact,
                                           onInputChange: contactOnInputChange,
                                           autocomplete: 'off',
                                           placeholder:'Search for a contact'}"
                            @selected="contactSelectHandler"
                          >
                            <template slot-scope="{suggestion}">
                              <span class="my-suggestion-item">{{ suggestion.item.full_name }} ({{ suggestion.item.email }})</span>
                            </template>
                          </vue-autosuggest>
                        </div>
                        <div
                          v-else-if="contact"
                          class="text-grey-darker leading-loosen"
                        >
                          <strong v-if="contact.company_name"> {{ contact.company_name }}<br></strong>
                          <div v-if="contact.same_as_billing_address">
                            {{ contact.full_name }}<br>
                            <pre style="font-family:inherit">{{ contact.address }}</pre>
                            <pre
                              v-if="contact.city"
                              style="font-family:inherit"
                            >{{ contact.city }}<span v-if="contact.postal_code">,</span> {{ contact.postal_code }}</pre>
                            {{ contact.country }} <br>
                          </div>
                          <div v-if="!contact.same_as_billing_address">
                            {{ contact.billing_first_name }} {{ contact.billing_last_name }}
                            {{ !contact.billing_first_name && !contact.billing_last_name ? contact.full_name : "" }}
                            <br>
                            <pre style="font-family:inherit">{{ contact.billing_address }}</pre>
                            <pre
                              v-if="contact.billing_city"
                              style="font-family:inherit"
                            >{{ contact.billing_city }}<span v-if="contact.billing_postal_code">,</span> {{ contact.billing_postal_code }}</pre>
                            {{ contact.billing_country }} <br>
                          </div>
                          <span v-if="contact.vat">{{ contact.vat }} <br></span>
                          <a
                            class="cursor-pointer blue underline"
                            @click="editContact"
                          > Edit client details </a><br />
                          <a
                            class="cursor-pointer blue underline"
                            @click="addContact"
                            v-if="!project"
                          >Choose different client</a>
                        </div>
                        <div v-else>
                          <a
                            class="cursor-pointer green underline leading-loosen"
                            @click="addContact"
                          >Choose a client</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-10 w-full px-8">
                  <h3>
                    Deliverables
                  </h3>
                  <p class="note">
                    Items that are included in the invoice, that a client has is not able to select.
                  </p>
                  <div class="note mb-3">
                    <strong>Tip</strong>: You can use tokens like <strong>@clientFirstName</strong> here as well. <a href="https://intercom.help/octoa/en/articles/7135447-using-tokens-in-the-octoa-tool" target="_blank">Learn more</a>.
                  </div>
                </div>
                <div class="heading">
                  <div class="flex px-8 w-full font-bold">
                    <div class="w-5/12">
                      {{ $translate('item', invoice.locale) }}
                    </div>
                    <div class="w-7/12 px-2 hidden lg:flex flex-grow">
                      <div class="flex w-1/2">
                        <div class="pr-2">
                          {{ $translate('quantity', invoice.locale) }}
                        </div>
                        <div
                          v-if="isTaxEnable"
                          class="px-2 w-20"
                        >
                          {{ $translate('tax', invoice.locale) }}
                        </div>
                        <div class="px-2">
                          {{ $translate('price', invoice.locale) }}
                        </div>
                      </div>
                      <div class="w-1/2 px-2 flex justify-between">
                        <div>
                          {{ $translate('dueItem', invoice.locale) }}
                        </div>
                        <div class="text-right">
                          {{ $translate('total', invoice.locale) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Required list items -->
                <div class="px-4">
                  <!-- Draggable -->
                  <draggable
                    :list="listofItems"
                    handle=".drag"
                    drag-class="drag-helper"
                    animation="200"
                  >
                    <transition-group
                      type="transition"
                      name="flip-list"
                    >
                      <div
                        v-for="(item, index) in listofItems"
                        :key="item.id"
                      >
                        <div class="draggable-item">
                          <div class="drag">
                            <div class="bg">
                              <img
                                src="@/assets/img/icons/scroll.svg"
                                width="15"
                              >
                            </div>
                          </div>
                          <div
                            class="delete"
                            @click="removeItem(index)"
                          >
                            <div class="bg">
                              <img
                                src="@/assets/img/icons/delete.svg"
                                width="25"
                              >
                            </div>
                          </div>
                          <div
                            class="quoteItem flex flex-wrap w-full px-2 pt-6 pb-2"
                            style="background:transparent;"
                          >
                            <div class="w-full lg:w-5/12 px-2">
                              <vue-autosuggest
                                :ref="'autoSuggestId'+item.id"
                                :suggestions="suggestions"
                                :input-props="{id:'autosuggest__input',
                                               initialValue: item.name,
                                               onInputChange: autoSuggestOnInputChange,
                                               autocomplete: 'off',
                                               placeholder:'Item name'}"
                                @blur="autoSuggestBlurHandler(item)"
                                @click="autoSuggestClickHandler(item)"
                                @selected="autoSuggestSelectHandler"
                              >
                                <template slot-scope="{suggestion}">
                                  <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                                </template>
                              </vue-autosuggest>

                              <textarea-autosize
                                v-model="item.description"
                                placeholder="Item description"
                                class="lg:hidden block mt-3 mb-3"
                                :min-height="100"
                                :max-height="300"
                              />
                            </div>

                            <div class="w-7/12 hidden lg:flex">
                              <div class="flex w-1/2">
                                <div class="px-2">
                                  <input
                                    v-model="item.quantity"
                                    type="number"
                                    min="0"
                                    class="quantity"
                                    name="quantity"
                                    @input="checkQuantity(item, $event)"
                                  >
                                </div>
                                <div
                                  v-if="isTaxEnable"
                                  class="px-2 w-20"
                                >
                                  <div class="select-container">
                                    <select v-model="item.tax">
                                      <option
                                        v-for="tax in taxes"
                                        :key="tax.id"
                                        :value="parseFloat(tax.value).toFixed(2)"
                                        :selected="(item.tax == tax.value) ? 'selected' : ''"
                                      >
                                        {{ tax.value }}%
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div class="px-2">
                                  <vue-numeric
                                    v-model="item.price"
                                    :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                                    :precision="2"
                                    class="small-right"
                                    :currency="currency.sign"
                                    :minus="true"
                                    :decimal-separator="currency.decimal_separator"
                                    :thousand-separator="currency.thousand_separator"
                                  />
                                </div>
                              </div>
                              <div class="px-2 w-1/2 flex justify-between">
                                <div class="flex items-center">
                                  <div class="w-18">
                                    <vue-numeric
                                      v-model="item.due_percentage"
                                      :precision="2"
                                      :max="100"
                                      class="w-18"
                                    />
                                  </div>&nbsp; %
                                </div>
                                <div class="mt-3 pr-2 text-right break-words">
                                  <vue-numeric
                                    :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                                    :precision="2"
                                    read-only
                                    read-only-class="font-bold text-base"
                                    :currency="currency.sign"
                                    :minus="false"
                                    :decimal-separator="currency.decimal_separator"
                                    :thousand-separator="currency.thousand_separator"
                                    :min="0"
                                    :value="calculateUnitPrice(item)"
                                  />
                                </div>
                              </div>
                            </div>

                            <!-- Mobile -->
                            <div class="flex flex-wrap lg:hidden justify-between w-full pb-5 px-2">
                              <div class="w-1/2 mb-3 pr-3">
                                <div class="font-bold mb-1">
                                  {{ $translate('quantity', invoice.locale) }}
                                </div>
                                <input
                                  v-model="item.quantity"
                                  type="number"
                                  min="0"
                                  class="quantity"
                                  name="quantity"
                                  @input="checkQuantity(item, $event)"
                                >
                              </div>
                              <div
                                v-if="isTaxEnable"
                                class="w-1/2 "
                              >
                                <div class="font-bold mb-1">
                                  {{ $translate('tax', invoice.locale) }}
                                </div>
                                <div class="select-container">
                                  <select v-model="item.tax">
                                    <option
                                      v-for="tax in taxes"
                                      :key="tax.id"
                                      :value="parseFloat(tax.value).toFixed(2)"
                                      :selected="(item.tax == tax.value) ? 'selected' : ''"
                                    >
                                      {{ tax.value }}%
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div
                                class="w-1/2 "
                              >
                                <div class="font-bold mb-1">
                                  {{ $translate('dueItem', invoice.locale) }}
                                </div>
                                <div class="flex items-center">
                                  <div class="w-18">
                                    <vue-numeric
                                      v-model="item.due_percentage"
                                      :precision="2"
                                      :max="100"
                                      class="w-18"
                                    />
                                  </div>
                                  <div>&nbsp; %</div>
                                </div>
                              </div>
                              <div
                                class="w-1/2"
                                :class="{'pr-3' : isTaxEnable}"
                              >
                                <div class="font-bold mb-1">
                                  {{ $translate('price', invoice.locale) }}
                                </div>
                                <vue-numeric
                                  v-model="item.price"
                                  :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                                  :precision="2"
                                  class="small-right"
                                  :currency="currency.sign"
                                  :minus="true"
                                  :decimal-separator="currency.decimal_separator"
                                  :thousand-separator="currency.thousand_separator"
                                />
                              </div>
                              <div class="w-full mt-4">
                                <div class="font-bold mb-1">
                                  {{ $translate('total', invoice.locale) }}
                                </div>
                                <div class="mt-4">
                                  <vue-numeric
                                    :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                                    :precision="2"
                                    read-only
                                    :currency="currency.sign"
                                    :minus="false"
                                    :decimal-separator="currency.decimal_separator"
                                    :thousand-separator="currency.thousand_separator"
                                    :min="0"
                                    :value="calculateUnitPrice(item)"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="flex px-4 -mt-4">
                            <div class="w-4/5 lg:w-full">
                              <label class="pt-2 py-3 block text-grey-dark">
                                <textarea-autosize
                                  v-model="item.description"
                                  placeholder="Item description"
                                  class="hidden lg:block mt-6 mb-3"
                                  :style="[isTaxEnable ? {'max-width': '710px'} : {'max-width': '620px'}]"
                                  :min-height="100"
                                  :max-height="300"
                                />

                                <v-checkbox
                                  :id="`item${item.id}-isTemplate`"
                                  v-model="item.isTemplate"
                                  :checked="item.isTemplate"
                                  classes="small"
                                  label="Save item for future use"
                                />
                              </label>
                            </div>
                            <div class="w-1/5 text-right">
                              <div
                                class="block lg:hidden"
                                @click="removeItem(index)"
                              >
                                <img
                                  src="@/assets/img/icons/delete.svg"
                                  width="25"
                                >
                              </div>
                            </div>
                          </div>
                          <div class="mt-3 mx-4">
                            <hr>
                          </div>
                        </div>
                      </div>
                    </transition-group>
                  </draggable>
                  <!-- End Draggable -->
                </div>

                <!-- Sub total -->
                <div class="flex flex-wrap px-8 mt-10 pb-3">
                  <div class="w-full lg:w-3/5">
                    <a
                      class="underline green text-sm mt-3 mb-5 cursor-pointer"
                      @click="addItem"
                    >
                      + Add new item
                    </a>
                  </div>
                  <div class="w-full mt-8 lg:mt-0 lg:w-2/5 text-base flex items-center flex-wrap ">
                    <div
                      class="w-1/2 "
                    >
                      {{ $translate('discount', invoice.locale) }}
                    </div>
                    <div
                      class="w-1/2 items-center text-right"
                    >
                      <vue-numeric
                        v-model="discount"
                        :precision="2"
                        class="small-right"
                        :minus="false"
                        :min="0"
                      /> &nbsp; %
                    </div>
                    <div
                      v-if="isTaxEnable"
                      class="w-full flex flex-wrap"
                    >
                      <hr
                        class="my-5"
                      >
                      <div class="w-1/2 text-grey-darker">
                        {{ $translate('subtotal', invoice.locale) }} <span v-if="isTaxEnable && isTaxPriceIncluded">({{ $translate('exclTax', invoice.locale) }})</span>
                      </div>
                      <div class="w-1/2 text-right">
                        <vue-numeric
                          :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                          :precision="2"
                          read-only
                          :currency="currency.sign"
                          :minus="false"
                          :decimal-separator="currency.decimal_separator"
                          :thousand-separator="currency.thousand_separator"
                          :min="0"
                          :value="subTotal"
                        />
                      </div>
                      <div
                        v-for="(tax, key) in taxClasses"
                        :key="key"
                        class="w-full flex flex-wrap mt-5"
                      >
                        <hr
                          v-if="isTaxEnable"
                          class="mb-4"
                        >
                        <div
                          v-if="isTaxEnable"
                          class="w-1/2"
                        >
                          {{ $translate('tax', invoice.locale) }} {{ key }}%
                        </div>
                        <div
                          v-if="isTaxEnable"
                          class="w-1/2 text-right"
                        >
                          <vue-numeric
                            :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                            :precision="2"
                            read-only
                            :currency="currency.sign"
                            :minus="false"
                            :decimal-separator="currency.decimal_separator"
                            :thousand-separator="currency.thousand_separator"
                            :min="0"
                            :value="tax*(duePercentage/100)"
                          />
                        </div>
                      </div>
                    </div>


                    <hr class="my-5 thick">

                    <div class="w-1/2">
                      {{ $translate('total', invoice.locale) }} <span v-if="isTaxEnable && isTaxPriceIncluded">({{ $translate('inclTax', invoice.locale) }})</span>
                    </div>
                    <div class="w-1/2 text-right">
                      <vue-numeric
                        :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                        :precision="2"
                        read-only
                        :currency="currency.sign"
                        :minus="false"
                        :decimal-separator="currency.decimal_separator"
                        :thousand-separator="currency.thousand_separator"
                        :min="0"
                        :value="total*(duePercentage/100)"
                      />
                    </div>


                    <div
                      v-for="(payment, key) in payments"
                      :key="key"
                      class="w-full"
                    >
                      <hr
                        v-if="payments.length > 0"
                        class="my-5"
                      >

                      <div
                        v-if="payment.reference != 'delete'"
                        class="flex items-center justify-between"
                      >
                        <div class="mr-3">
                          {{ $translate('paymentReceived', invoice.locale) }} <br>
                          <span v-if="payment.state == 'temp'" class="text-grey-semi-light text-sm"> {{ $translate('date', invoice.locale) }}: {{ payment.preview_date | viewDate(invoice.locale) }} </span>
                          <span v-else class="text-grey-semi-light text-sm"> {{ $translate('date', invoice.locale) }}: {{ payment.date | viewDate(invoice.locale) }} </span>
                        </div>
                        <div class="flex items-center">
                          <div
                            class="mr-3 inline-block cursor-pointer"
                            @click="removePayment(payment)"
                          >
                            <img
                              src="@/assets/img/icons/delete.svg"
                              width="18"
                            >
                          </div>
                          <div>
                            -
                            <vue-numeric
                              v-model="payment.amount"
                              :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                              :precision="2"
                              read-only
                              class="ml-2 small-right"
                              :currency="currency.sign"
                              :minus="false"
                              :decimal-separator="currency.decimal_separator"
                              :thousand-separator="currency.thousand_separator"
                              :min="0"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr class="my-5 thick">

                    <div class="w-1/2 text-lg font-bold">
                      {{ $translate('balanceDue', invoice.locale) }} ({{ currency.code }})
                    </div>
                    <div class="w-1/2 text-lg font-bold text-right">
                      <vue-numeric
                        :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                        :precision="2"
                        read-only
                        :currency="currency.sign"
                        :minus="false"
                        :decimal-separator="currency.decimal_separator"
                        :thousand-separator="currency.thousand_separator"
                        :min="0"
                        :value="remainingDueTotal"
                      />
                    </div>
                  </div>
                </div>
                <div class="flex px-8 mt-10">
                  <textarea
                    v-model="notes"
                    placeholder="Additional notes"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="max-w-xl mx-auto px-4 lg:px-0">
            <div
              class="flex flex-wrap mt-5 mb-10  text-right"
            >
              <div
                v-if="isSaving"
                class="w-full"
              >
                <img
                  src="@/assets/img/icons/loader.svg"
                  width="40"
                >
              </div>
              <div
                v-else
                class="w-full"
              >
                <div class="inline-block mb-3">
                  <a
                    v-if="invoice.state == 'draft'"
                    class="ml-3 green underline cursor-pointer"
                    @click="save(true)"
                  >Save and edit later</a>
                  <a
                    class="ml-3 cursor-pointer green underline"
                    @click="saveAndPreview"
                  >Save and view</a>
                </div>
                <v-button
                  v-if="invoice.state == 'draft'"
                  class="ml-3"
                  @click="showEmailSlideIn"
                >
                  Compose email
                </v-button>
                <v-button
                  v-else
                  class="ml-3"
                  @click="save(true)"
                >
                  Save
                </v-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!loading && actionDates"
        id="history"
        class="text-grey-darker px-4 max-w-xl mx-auto "
      >
        <div class="font-bold">
          Invoice history
        </div>
        <li
          v-for="date in actionDates"
          :key="date.id"
        >
          {{ date.label }} {{ date.timestamp | humanDateWithTimeStamp(timezone) }}
        </li>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import auth from '@/auth'
import InvoiceBankSlideIn from './Extension/InvoiceBankSlideIn'
import InvoiceContactSlideIn from './Extension/InvoiceContactSlideIn'
import InvoiceEmailSlideIn from './Extension/InvoiceEmailSlideIn'
import InvoiceSelectContactSlideIn from './Extension/InvoiceSelectContactSlideIn'

import { logException } from '@/helpers'
import { Container, Draggable } from 'vue-smooth-dnd'
import { trim2Decimals, round, calculateDiscountTotal, calculateEachTaxClassPrice, calculateTaxTotal} from '@/utils/helpers'
import { addDays, format } from 'date-fns'
import { createCancelSource } from '@/http'
import { convertToTimeZone } from 'date-fns-timezone'
import draggable from 'vuedraggable'
import filter from "lodash.filter";

let cancelSource

export default {
  components: {
    draggable,
    InvoiceBankSlideIn,
    InvoiceContactSlideIn,
    InvoiceEmailSlideIn,
    InvoiceSelectContactSlideIn,
  },
  data(){
    return {
      isPaymentFieldEmpty: false,
      showBankSlide: false,
      showContactSlide: false,
      showSelectContactSlide: false,
      metaTitle: 'Loading',
      hasTaxes: false,
      taxes: {},
      currency:{},
      logo: {path:'', width:0, height:0 },
      addPayment: {
        date: null,
        amount:0,
      },
      payments: [],
      isSaving: false,
      isSaved: false,
      loading: false,
      lastInvoiceNumber: null,
      generatedInvoiceNumber: null,
      invoiceId: null,
      project: null,
      invoice: {
        state: 'draft',
        locale: 'en',
      },
      name: '',
      isTaxEnable: false,
      isTaxPriceIncluded: false,
      taxPercent: 21,
      invoiceNumber: null,
      issuedAt: new Date(),
      validUntil: null,
      discount: 0,
      selectedListId: null,
      suggestions: [],
      listofItems: [],
      notes: '',
      contact: null,
      isSendingMail: false,
      isPreview: false,
      user: auth.user(),
      loadTemplateId: null,
      userMightUpdate: false,
      timezone: '',
      tooltipContent: {
        content: `
          *Various countries and cases have a different way of calculating tax. For example, in Europe when servicing consumers your prices have to be including tax. When in the US the tax is added on top of the price.
        `
      },
      logoTooltipContent: {
        content: `
          Upload your logo preferably with a minimum resolution of 500px width or 500px height with extension JPG or PNG.
        `
      },
      invoiceNumberToolTipContent: {
        content: `
          The moment you send out the invoice or mark it as sent, an invoice number will be automatically generated based on your last invoice number.
        `
      },
      actionDates: null,
      duePercentage: 100,
      uploadLoading: false,
      isShowTaxModal: false,
      isAutoGenerateInvoiceNumber: true,
      emailEditMode: 'edit',
      showEmailSlide: false,
      subject: null,
      email: {
        emailType: 'invoice',
        emailId: null,
        isBlankEmail: false,
        autocompleteEmailTagItems: [],
        selectEmailTemplate: '',
        emailTags: [],
        templates: [],
        fromEmailView: '',
        toEmail: '',
        fromEmail: '',
        subjectEmail:'',
        contentEmail: '',
        emailUpdatePending: false,
      },
    }
  },
  computed: {
    activeLanguages() {
      const languages = filter(this.user.companySettings.locales, (language => language.isActive))

      return languages
    },
    allowPaymentSchedule(){
      if(this.isInvoiceGenerated){
        this.isPaymentScheduleActive = true
        return true
      }
      this.isPaymentScheduleActive = false
      return false
    },
    originalSubTotal(){
      return this.listofItems.reduce(function (total, item) {
        return (Number(total) + Number((item.price*(item.due_percentage/100))*item.quantity)).toFixed(2)
      }, 0)
    },
    subTotal(){
      let originalSubTotal = this.listofItems.reduce(function (total, item) {
        return (Number(total) + Number((item.price*(item.due_percentage/100))*item.quantity)).toFixed(2)
      }, 0)

       /*
        When calculating subtotal, tax included will take overall discounted subtotal
        Tax excluded will take each item as discount
       */

      //let discountTotal = calculateDiscountTotal(this.listofItems, this.discount, this.discountTotal, this.isTaxEnable, this.isTaxPriceIncluded)
      let subTotal = ((originalSubTotal - this.discountTotal) * (this.duePercentage/100))

      if(this.isTaxEnable && this.isTaxPriceIncluded){
        subTotal = ((originalSubTotal - this.discountTotal) * (this.duePercentage/100))
        let tax = (this.taxTotal * (this.duePercentage/100))
        let value =  parseFloat(subTotal - tax)

        return trim2Decimals(value)
      }

      return trim2Decimals(subTotal)
    },
    discountTotal(){
      let total = 0
      if(this.originalSubTotal > 0){
        total = this.originalSubTotal * (this.discount/100)
      }
      return trim2Decimals(total)
    },
    discountedTotal(){
      let total = 0
      if(this.discountTotal > 0){
        total = this.originalSubTotal - this.discountTotal
      }

      return trim2Decimals(total)
    },
    taxClasses(){
      let results = calculateEachTaxClassPrice(this.listofItems, this.originalSubTotal, this.discountedTotal, this.isTaxPriceIncluded)
      return results
    },
    taxTotal(){
      let results = calculateTaxTotal(this.listofItems, this.originalSubTotal, this.discountedTotal, this.isTaxPriceIncluded)
      return results
    },
    viewDiscountedTotal(){
      if (!this.discountTotal) {
        return 0
      }

      let taxPrice = this.isTaxPriceIncluded && this.isTaxEnable ? this.taxTotal : 0
      let discountTotal = (parseFloat(this.subTotal) + taxPrice + parseFloat(this.discountTotal)) * (parseFloat(this.discount)/100)

      return discountTotal
    },
    total(){
      let total = 0
      let discountTotal = this.viewDiscountedTotal

      if(this.isTaxEnable && this.isTaxPriceIncluded){
        discountTotal = this.discountTotal
      }

      if(Math.sign(this.originalSubTotal) == -1){
        total = parseFloat(this.originalSubTotal) + parseFloat(discountTotal)
      } else {
        total = parseFloat(this.originalSubTotal) - parseFloat(discountTotal)
      }

      if(this.isTaxEnable && !this.isTaxPriceIncluded){
        if (this.taxTotal > 0) {
          total += this.taxTotal
        } else {
          total -= parseFloat(this.taxTotal.toString().replace('-', ''))
        }
      }

      return (Number(total)).toFixed(2)
    },
    dueTotal(){
      let total = this.total * (this.duePercentage/100)
      return (total)
    },
    remainingDueTotal(){
      let paymentTotal = 0
      this.payments.forEach(payment => {
        if(payment.reference == 'paid' && payment.reference != 'delete'){
          paymentTotal += Number(payment.amount)
        }
      })

      let remaining = Number(this.dueTotal) - Number(paymentTotal)

      return remaining
    },
  },
  async mounted(){
    let newItem = true
    this.loading = true

    await this.initData()

    if (this.$route.params.invoiceId) {
      newItem = false
      await this.getInvoice(this.$route.params.invoiceId)
      if(this.subject == '' || this.subject == null){
        this.metaTitle = `Edit Invoice: ${this.name}`
      } else if(this.name == '' || this.name == null){
        this.metaTitle = `Edit Invoice: ${this.subject}`
      } else {
        this.metaTitle = 'Edit Invoice'
      }
    } else {
      if (!this.$route.params.id) {
        this.showSelectContactSlide = true
      }

      if (!this.$route.params.id && this.loadTemplateId) {
        this.showSelectContactSlide = true
      }
    }

    if(this.$route.query.load && !this.$route.query.proposal && this.loadTemplateId){
      newItem = false
      await this.getInvoice(this.loadTemplateId, 'template')
      this.metaTitle = 'New Invoice'
      this.subject = this.project ? this.project.name : ""
      this.invoice.locale = this.user.companySettings.defaultLanguage
    }

    if(this.$route.query.load && this.$route.query.proposal && this.loadTemplateId){
      newItem = false
      await this.getProposal(this.loadTemplateId)
      console.log("yo")
      this.metaTitle = 'New Invoice'
      this.subject = this.project ? this.project.name : ""
    }

    try {
      const { data } = await this.$api.get('invoice').lastInvoiceNumber()
      this.lastInvoiceNumber = data.lastInvoiceNumber
      this.generatedInvoiceNumber = this.invoiceNumber

      if(this.invoiceNumber == null){
        this.invoiceNumber = data.newNumber
        this.generatedInvoiceNumber = data.newNumber
      }

    } catch(e){
      logException(e)
    }

    this.loading = false
    await this.loadMailTemplates()

    if(!this.loading && newItem){
      this.metaTitle = 'New Invoice'
      this.subject = this.project ? this.project.name : ''
      if (!this.invoice.locale) {
        this.invoice.locale = this.user.companySettings.defaultLanguage
      }

      //this.addItem()
    }

    await this.refreshCompanyDetails()

    this.initDetectChanges()
  },
  methods: {
    checkQuantity(item, $event){
      let value = $event.target.value
      if(value < 0){
        item.quantity = 0
      }
      if(value > 1000){
        item.quantity = 1000
      }
    },
    switichAutoGenerateInvoiceNumber(){
      this.isAutoGenerateInvoiceNumber = false
    },
    removePayment(payment){
      payment.reference = 'delete'
    },
    async alertExit(){
      if(this.userMightUpdate){
        let link

        if (this.$route.params.id) {
          link = `/projects/${this.$route.params.id}`
        } else {
          link = '/invoices'
        }

        const canDelete = await this.$alert.confirm({
          title: 'Are you sure?',
          text: 'You might have unsaved changes, remember to save before closing.',
        })

        if (canDelete) {
          this.$router.push(link)
        }
      }
    },
    initDetectChanges(){
      let elements = document.getElementsByTagName('input')
      let textAreaElements = document.getElementsByTagName('textarea')
      let that = this
      for(let i=0;i<elements.length;i++) {
        if(!(/submit/).test(elements[i].getAttribute('type'))) {
          elements[i].onchange = function(){that.detect(this)}
        }
      }
      for(let i=0;i<textAreaElements.length;i++) {
        if(!(/submit/).test(textAreaElements[i].getAttribute('type'))) {
          textAreaElements[i].onchange = function(){that.detect(this)}
        }
      }
    },
    detect(e){
      this.userMightUpdate = true
    },
    saveAndPreview(){
      this.isPreview = true
      this.save()
    },
    calculateUnitPrice(item){
      return (item.price*(item.due_percentage/100))*item.quantity
    },
    changedList(payload){
      this.listofItems = payload
    },
    editContact(){
      this.showContactSlide = true
    },
    addContact(){
      this.showSelectContactSlide = true
    },
    contactSelectHandler(payload){
      this.contact = payload.item
    },
    async contactOnInputChange(text, oldText){
      if (text === null) {
        /* Maybe the text is null but you wanna do
        *  something else, but don't filter by null.
        */
        return
      }
      let that = this

      this.suggestions = []
      try {

        const postData = {
          companyId: this.user.company.id,
          text: text,
        }

        const { data } = await this.$api.get('contacts').searchAutoSuggest(postData)
        that.suggestions = [{ data: data.results }]

      } catch(e){
        logException(e)
      }
    },
    autoSuggestBlurHandler(payload){
      let name = this.$refs['autoSuggestId'+payload.id][0].searchInput
      this.listofItems.find(function(item){
        if(item.id == payload.id) {
          item.name = name
        }
      })
    },
    async autoSuggestOnInputChange(text, oldText){
      if (text === null) {
        /* Maybe the text is null but you wanna do
        *  something else, but don't filter by null.
        */
        return
      }
      let that = this
      this.suggestions = []
      try {

        const postData = {
          companyId: this.user.company.id,
          name: text,
        }
        const { data } = await this.$api.get('templateProduct').search(postData)
        that.suggestions = [{ data: data.results }]

      } catch(e){
        logException(e)
      }
    },
    autoSuggestClickHandler(item) {
      this.selectedListId = item.id
    },
    autoSuggestSelectHandler(payload){
      let that = this
      let suggestedItem = payload.item

      this.listofItems.find(function(item){
        if(item.id == that.selectedListId) {
          item.name = suggestedItem.name
          item.price = suggestedItem.price
          item.description = suggestedItem.description
          item.unit = suggestedItem.unit
          item.quantity = suggestedItem.quantity
          item.isTaxEnable = suggestedItem.is_taxable
          item.tax = suggestedItem.tax
          item.due_percentage = 100
        }
      })
    },
    dateFormatter(value) {
      return format(value, 'MMM Do, YYYY')
    },
    dateFormatSave(value) {
      return format(value, 'YYYY-MM-DD')
    },
    addItem() {
      this.listofItems.push({
        id: new Date().getTime(),
        name: '',
        description: '',
        quantity: 1,
        price: 0,
        isTaxEnable: this.isTaxEnable,
        isTemplate: false,
        tax: this.taxPercent,
        due_percentage: 100,
        isHover:false,
      })
      this.userMightUpdate = true
    },
    removeItem(index){
      if(this.listofItems.length > 0) {
        this.listofItems.splice(index, 1)
      }
    },
    async save(saveAndEditLater = false) {

      // if(this.name == ''){
      //   this.$toasted.global.general_error({
      //     message : 'Please fill in a invoice name.'
      //   })
      //   return false
      // }

      let requireListItemsLength = this.listofItems.length
      if(requireListItemsLength == 0){
        this.$toasted.global.general_error({
          message : 'Please add at least one item to continue.'
        })
        return false
      }

      if(!this.contact && !this.project){
        this.$toasted.global.general_error({
          message : 'Choose a client.'
        })
        return false
      }

      if(!this.issuedAt){
        this.$toasted.global.general_error({
          message : 'Issued date cannot be empty.'
        })
        return false
      }

      if(!this.isAutoGenerateInvoiceNumber){

         const postData = {
          companyId: this.user.company.id,
          invoiceId: this.invoiceId,
          invoiceNumber: this.invoiceNumber,
        }

        try {
          const { data } = await this.$api.get('invoice').checkDuplicateInvoiceNumber(postData)
          if(data.status == 'exist'){
            const canContinue = await this.$alert.confirm({
              title: 'An invoice number: '+this.invoiceNumber+' already existed.',
              text: 'Are you sure you want to continue?',
            })

            if (!canContinue) {
              return false
            }
          }
        } catch(e){
          logException(e)
        }
      }

      return this.doSave(saveAndEditLater)
    },
    async doSave(saveAndEditLater = false) {

      this.isSaving = true
      try {
        const postData = {
          companyId: this.user.company.id,
          invoiceId: this.invoiceId,
          invoiceNumber: this.invoiceNumber,
          projectId: this.project ? this.project.id : null,
          contactId: this.contact ? this.contact.id : null,
          data: this._data,
          currencyId: this.currency.id,
          locale: this.invoice.locale,
          issuedAt:(this.issuedAt != null) ? this.dateFormatSave(this.issuedAt) : null,
          validUntil:(this.validUntil != null) ? this.dateFormatSave(this.validUntil) : null,
          subTotal: this.subTotal,
          taxTotal: this.taxTotal,
          discountTotal: this.discountTotal,
          total: this.total,
          duePercentage: this.duePercentage,
          payments: this.payments,
        }

        const { data } = await this.$api.get('invoice').save(postData)

        this.invoiceId = data.invoice.id
        this.invoice = data.invoice

        if(saveAndEditLater){
          this.$router.push(`/projects/${this.$route.params.id}`)
          return false
        }

        if(this.$route.query.load && this.loadTemplateId && this.project && this.invoiceId && this.$route.params.invoiceId && !this.isPreview){
          window.open(`/projects/${this.$route.params.id}/invoice/${this.invoiceId}?send=1`, '_self')
          return false
        }

        if(!this.email.contentEmail){
          this.generateEmailContent()
        }

        if(this.isPreview){
          let popUp = this.$router.push(`/invoices/preview/${this.invoiceId}`)

          // if(!popUp || popUp.closed || typeof popUp.closed=='undefined')
          // {
          //   this.$toasted.global.general_error({
          //     message : 'Opening a new window is blocked. Please add us to your exception list.'
          //   })
          //   return false
          // }
          this.isPreview = false
        } else {
          if(this.isPaymentFieldEmpty){
            this.showBankSlide = true
           } else {
            this.email.emailId = this.invoiceId
            this.showEmailSlide = true
          }
        }

        this.userMightUpdate = false
        this.isSaved = true
        this.isSaving = false

        return true

      } catch(e){
        logException(e)
      }
    },
    async getProposal(id){

      let that = this

      try {
        const { data } = await this.$api.get('proposal').detail(`${id}`, 'edit')

        let proposal = data.proposal
        this.isTaxEnable = proposal.is_tax_enable
        this.isTaxPriceIncluded = proposal.is_tax_price_included
        this.taxPercent = proposal.tax
        this.discount = proposal.discount
        // this.issuedAt = new Date(proposal.issued_at)
        // this.validUntil = new Date(proposal.valid_until)

        if(!this.hasTaxes){
          this.isTaxEnable = false
        }

        let that = this
        proposal.items.forEach(function(item) {

          let quantity = item.quantity
          if(quantity.includes(".00")){
            item.quantity = parseInt(item.quantity)
          }

          if(!item.is_optional || (item.is_optional && item.is_selected)){
            item.isTaxEnable = (!that.hasTaxes) ? false : item.is_taxable
            that.listofItems.push(item)
          }
        })
      } catch(e){
        logException(e)
      }
    },
    async getInvoice(id, type = null){
      let that = this
      try {

        let result = {}
        if(type == 'template'){
          result = await this.$api.get('templateInvoice').detail(this.user.company.id, id)
          this.subject = result.data.invoice.name
        } else {
          result  = await this.$api.get('invoice').detail(id, 'edit')
          this.name = result.data.invoice.name
          this.subject = result.data.invoice.subject
          this.invoice.locale = result.data.invoice.locale
          this.contact = result.data.invoice.project ? result.data.invoice.project.contact : result.data.invoice.contact
        }

        let invoice = result.data.invoice

        this.actionDates = result.data.actionDates
        this.invoice = invoice

        this.invoice.state = (!this.invoice.state) ? 'draft' : this.invoice.state
        this.isAutoGenerateInvoiceNumber = invoice.is_auto_generate_invoice_number
        this.issuedAt = new Date()

        // add vat percentage to invoice items
        if (type == 'template') {
          invoice.items.forEach(item => {
            item.tax = this.taxPercent
          })
        }

        if(invoice.invoice_number){
          this.invoiceNumber = invoice.invoice_number
        }

        if(this.invoice.state != 'draft'){
          this.isAutoGenerateInvoiceNumber = 0
        }

        this.isTaxEnable = invoice.is_tax_enable
        console.log('this.isTaxEnable', this.isTaxEnable)
        this.isTaxPriceIncluded = invoice.is_tax_price_included
        this.discount = invoice.discount
        this.notes = invoice.notes

        if(invoice.valid_until){
          this.validUntil = addDays(new Date(), invoice.valid_period)
        }

        if(type != 'template'){

          this.currency = invoice.currency
          this.invoiceId = invoice.id

          if(invoice.issued_at){
            let date = new Date(invoice.issued_at)
            this.issuedAt  = convertToTimeZone(date, { timeZone: 'Etc/UTC' })
          }

          if(invoice.valid_until){
            let date = new Date(invoice.valid_until)
            this.validUntil  = convertToTimeZone(date, { timeZone: 'Etc/UTC' })
          }

          this.generateEmailContent()

          invoice.invoice_payments.forEach(payment => {
            this.payments.push({
              id: payment.id,
              state: 'added',
              invoice_id: payment.invoice_id,
              date: payment.date,
              amount: payment.amount,
              reference: 'paid'
            })
          })
          await this.loadDraft()
        }

        // If tax happens to be deleted reset
        if(!this.hasTaxes){
          this.isTaxEnable = false
        }

        let that = this
        invoice.items.forEach(function(item) {

          let quantity = String(item.quantity)
          if(quantity.includes(".00")){
            item.quantity = parseInt(item.quantity)
          }

          item.isTaxEnable = (!that.hasTaxes) ? false : item.is_taxable
          if(item.is_optional){
            that.listofAdditonalItems.push(item)
          } else {
            that.listofItems.push(item)
          }
        })

        if(this.$route.query.send){
          if(this.isPaymentFieldEmpty){
            this.showBankSlide = true
          } else {
            this.$modal.show('emailModal')
          }
        }

        this.loading = false

      } catch(e){
        logException(e)
      }
    },
    redirectBack(){
      this.$router.push(`/projects/${this.$route.params.id}`)
    },
    showEmailSlideIn(){
      this.isPreview = false
      this.save()
    },
    async savedEmailSlideIn(){
      this.showEmailSlide = false
      this.isSendingMail = false

      if(this.user.companySettings.moneybirdSettings.auto_export_invoices){
        try {
          await this.$api.get('invoice').exportMoneybird(this.user.company.id, {invoiceId: this.invoiceId})
          this.$router.push(`/projects/${this.$route.params.id}`)

        } catch(e){
          this.$toasted.global.api_error(e)
        }
      } else {
        this.$router.push(`/projects/${this.$route.params.id}`)
      }
    },
    async hideEmailSlideIn(){
      this.showEmailSlide = false
    },
    async loadMailTemplates(){
      try {
        const { data } = await this.$api.get('templateMail').list(this.user.company.id)
        this.email.templates = []

        data.mails.forEach(template => {
          this.email.templates.push({template})
        })
      } catch(e){
        logException(e)
      }
    },
    async loadDraft(){

      this.email.selectEmailTemplate = ''

      try {
        const { data } = await this.$api.get('mail').loadDraft(`${this.invoiceId}`, 'invoice')

        if(data.result != 'Empty'){

          this.email.emailTags = []
          data.toEmails.forEach(email => {
            this.email.emailTags.push({
              text: email,
            })
          })

          this.email.fromEmail = data.from
          this.email.fromEmailView = `From: ${this.user.companySettings.senderName} <${this.user.companySettings.senderEmail}>`
          this.email.subjectEmail = data.subject
          this.email.contentEmail = data.content
          this.email.emailUpdatePending = true
        }
      } catch(e){
        logException(e)
      }
    },
    updateEmailTags(newTags){
      this.email.autocompleteEmailTagItems = []
      this.email.emailTags = newTags
    },
    generateEmailContent(){
      this.email.emailTags = []
      this.email.emailTags.push({
        text: this.contact.email,
      })

      const signature = (!this.user.me.email_signature) ? '' : this.user.me.email_signature

      this.email.contentEmail = `<p>Hi ${this.contact.first_name}, </p><p></p><p>I hereby send you my invoice! Please contact me if you have any questions or if things are not clear. I’m glad to help you further. <p><p></p><p>Link to invoice: <a href="@documentLink">@documentLink</a> </p><p>${signature}</p>`
      this.email.emailUpdatePending = true
    },
    async initData(){

      const templateId = this.$store.getters['template/loadTemplateId']
      this.loadTemplateId = templateId

      // Load settings
      this.email.fromEmail = this.user.companySettings.senderEmail
      this.email.fromEmailView = `From: ${this.user.companySettings.senderName} <${this.user.companySettings.senderEmail}>`

      this.logo.path = this.user.companySettings.logo
      this.refreshLogo()

      this.currency = this.user.companySettings.currency
      this.taxes = this.user.companySettings.taxes

      if(this.taxes){
        if(this.taxes[0] !== undefined){
          this.hasTaxes = true
          this.taxPercent = parseInt(this.taxes[0].value).toFixed(2)
        }
      }

      this.validUntil = addDays(new Date(), this.user.companySettings.invoiceValidPeriod)
      this.isTaxPriceIncluded = (this.user.companySettings.includeTaxInPrice == 0) ? false : true
      this.timezone = this.user.companySettings.timezone

      if (this.$route.params.id) {
        try {
          const { data } = await this.$api.get('projects').load(this.$route.params.id)

          this.project = data.project
          if(data.project.currency){
            this.currency = data.project.currency
          }

          this.invoice.locale = data.locale ? data.locale : data.project ? data.project.locale : 'en'
          this.contact = data.project.contact

        } catch(e){
          //this.redirectBack()
          logException(e)
        }
      }
    },
    refreshLogo(){
      let that = this
      let image = new Image()
      image.src = this.logo.path
      image.onload = function () {
        that.logo.width = this.width
        that.logo.height = this.height
      }
    },
    async copyShareLink(){
      if(this.invoice.state == 'draft'){
        const can = await this.$alert.confirm({
          title: 'Invoice has not been sent out',
          text: 'In order to copy a share link, we will make this invoice as sent.'
        })

        if (can) {
          try {
            await this.$api.get('invoice').updateState(this.invoiceId, 'sent')
              let link
              if (this.invoice.project) {
                link = `${config.frontBaseUrl}/view/${this.invoice.project.id}/invoice/${this.invoice.token}`
              } else {
                link = `${config.frontBaseUrl}/view/invoice/${this.invoice.token}`
              }
              let dummy = document.createElement('textarea')
              document.body.appendChild(dummy)
              dummy.value = link
              dummy.select()
              document.execCommand('copy')
              document.body.removeChild(dummy)

              this.$toasted.global.general_success({
                message : 'Share link has been copied to your clipboard.'
              })

              setTimeout(() => {
                window.location.reload()
              }, 1000)

          } catch(e){
            this.$toasted.global.api_error(e)
            logException(e)
          }
        }
      } else {
        let link
        if (this.invoice.project) {
          link = `${config.frontBaseUrl}/view/${this.invoice.project.id}/invoice/${this.invoice.token}`
        } else {
          link = `${config.frontBaseUrl}/view/invoice/${this.invoice.token}`
        }
        let dummy = document.createElement('textarea')
        document.body.appendChild(dummy)
        dummy.value = link
        dummy.select()
        document.execCommand('copy')
        document.body.removeChild(dummy)

        this.$toasted.global.general_success({
          message : 'Share link has been copied to your clipboard.'
        })
      }
    },
    async deleteItem(){
      const canDelete = await this.$alert.confirm({
        title: 'Are you sure you want to delete this invoice?',
        text: 'If you delete this, sent item will no longer accessible by the client.',
      })

      if (canDelete) {
        try {
          try {
            const postData = {
              invoiceId: this.invoiceId,
            }
            await this.$api.get('invoice').delete(postData)
            this.$router.push(`/projects/${this.$route.params.id}`)

          } catch(e){
            this.$toasted.global.api_error(e)
            logException(e)
          }

        } catch(e){
          logException(e)
        }
      }
    },
    async markAsSent(){


      const can = await this.$alert.confirm({
        title: 'Are you sure you want to mark this invoice as sent?',
      })

      if (can) {
        try {
          await this.$api.get('invoice').updateState(this.invoiceId, 'sent')

          if(this.user.companySettings.moneybirdSettings.auto_export_invoices){
            try {
              await this.$api.get('invoice').exportMoneybird(this.user.company.id, {invoiceId: this.invoiceId})

              setTimeout(() => {
                window.location.reload()
              }, 500)

            } catch(e){
              this.$toasted.global.api_error(e)
            }
          } else {
            setTimeout(() => {
              window.location.reload()
            }, 500)
          }

        } catch(e){
          this.$toasted.global.api_error(e)
        }
      }
    },
    async markAsUnsent(){

      const can = await this.$alert.confirm({
        title: 'Are you sure you want to mark this invoice as draft?',
      })

      if (can) {
        try {
          await this.$api.get('invoice').updateState(this.invoiceId, 'draft')
          setTimeout(() => {
            window.location.reload()
          }, 500)

        } catch(e){
          this.$toasted.global.api_error(e)
          logException(e)
        }
      }
    },
    showAddPayment(){
      this.addPayment.date = new Date()
      this.addPayment.amount = this.remainingDueTotal
      this.$modal.show('paymentModal')
    },
    recordPayment(){
      if(this.addPayment.date == null || this.addPayment.amount <= 0){
          this.$toasted.global.general_error({
            message : 'Please enter a valid date or amount.'
          })
          return
      }

      this.payments.push({
        id: new Date().getTime(),
        state: 'temp',
        date: format(this.addPayment.date, 'YYYY-MM-DD'),
        preview_date: (format(this.addPayment.date, 'YYYY-MM-DD') != format(new Date(), 'YYYY-MM-DD')) ? addDays(this.addPayment.date, 1) : this.addPayment.date,
        amount: this.addPayment.amount,
        reference: 'paid'
      })

      this.addPayment.date = null
      this.addPayment.amount = 0
      this.$modal.hide('paymentModal')
    },
    async hideBankSlideIn(){
      this.showBankSlide = false
      this.$modal.show('emailModal')
      await this.refreshCompanyDetails()
    },
    async refreshCompanyDetails() {

      this.isPaymentFieldEmpty = false
      try {
        await this.$store.dispatch('company/refreshCompanyDetails')

        const company = this.$store.getters['company/details']

        if(company.invoiceFooterPayment){
          if(company.invoiceFooterPayment.length < 1){
            this.isPaymentFieldEmpty = true
          }
        }

      } catch(e) {
        this.$toasted.global.api_error(e)
      }
    },
    async hideContactSlideIn(){
      this.showContactSlide = false
    },
    async hideAddContactSlideIn() {
      this.showSelectContactSlide = false
    },
    async savedContactSlideIn(contactId){
      try {
        this.contact = null

        const { data } = await this.$api.get('contacts').load(contactId)

        this.contact = data.contact
        this.showContactSlide = false
        this.showSelectContactSlide = false

      } catch(e){
        logException(e)
      }
    },
    async fileUpload(){

      let that = this
      let _URL = window.URL || window.webkitURL
      let file = this.$refs.file.files[0]
      this.uploadLoading = true

      if (file.size > 5000 * 1024 || (file.type != 'image/jpeg' && file.type != 'image/jpg' && file.type != 'image/png')) {
        this.$toasted.global.general_error({
          message : 'Upload an image within 5mb, PNG or JPG format and a minimum resolution of 500px width or 500px height.'
        })
        this.uploadLoading = false
        return
      }

      let img = new Image()
      img.onload = function () {
        let hasError = false
        if(this.width > this.height){
          if(this.width < 500){
            hasError = true
          }
        }
        if(this.width < this.height){
          if(this.height < 500){
            hasError = true
          }
        }
        if(this.width == this.height){
          if(this.width < 500 && this.height < 500){
            hasError = true
          }
        }
        if(hasError){
          that.uploadLoading = false
          that.$toasted.global.general_error({
            message : 'Upload an image within 5mb, PNG or JPG format and a minimum resolution of 500px width or 500px height.'
          })
          return false
        }
      }
      img.src = _URL.createObjectURL(file)

      let formData = new FormData()
      formData.append('image', file)

      try {
        await this.$store.dispatch('company/uploadLogo', formData)

        try {
            await this.$store.dispatch('company/refreshCompanyDetails')
            const company = this.$store.getters['company/details']
            this.logo.path = company.logo
            this.refreshLogo()

            auth.refreshUser()
         } catch(e) {
          this.$toasted.global.api_error(e)
        }
      } catch(e) {
        this.$toasted.global.general_error({
          message : 'Please upload an image within 5mb, PNG or JPG format and a minimum resolution of 500x500 px.'
        })
      }

      this.uploadLoading = false
    },
    showTaxModal(){
      this.isShowTaxModal = true
    },
    closeTaxModal(){
      this.isShowTaxModal = false
    },
    async savedTaxModal(taxes, defaultTaxPercent){
      this.isShowTaxModal = false
      this.isTaxEnable = true
      this.taxes = taxes
      this.hasTaxes = true
      this.taxPercent = defaultTaxPercent

      let that = this
      if (this.invoice.items && this.invoice.items.length > 0) {
        this.invoice.items.forEach(function (item) {
          item.isTaxEnable = 1
          item.tax = that.taxPercent
        })
      }
      this.$modal.hide('addTaxModal')
    },
  },
}
</script>
